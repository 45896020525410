import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFound = () => {
  return (
    <Layout>
      <Seo title={`404: Not found`} />
      <div
        className="flex items-center justify-center"
        style={{ height: "40vh" }}
      >
        <div className="text-center">
          <h1 style={{ fontSize: "1.4em", fontWeight: "bold" }}>
            404 Page Not Found
          </h1>
          <p>You just hit a route that doesn't exist</p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
